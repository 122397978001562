import { useEffect } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import Store from 'store';

import { usePetPlanData } from '@pumpkincare/plans';
import {
  getPetPolicy,
  getQuoteActivePet,
  getQuoteId,
  getQuoteIdentityId,
  getQuoteVetId,
  useMutatePetPolicy,
  useQuote,
  useQuotesActiveId,
} from '@pumpkincare/quotes';
import {
  DOG,
  IDENTITY_LOCAL_STORAGE_KEY,
  UP_TO_5_MONTHS,
  UP_TO_11_MONTHS,
  useBooleanInput,
} from '@pumpkincare/shared';
import { LegalBody, Typography } from '@pumpkincare/shared/ui';

import QuoteEditModal from '../../quote-edit-modal';
import InsurancePickerBundle from './insurance-picker-bundle';

import styles from './picker.css';

function Picker({ onAddToCart, buttonText = 'Add to Cart', headerText }) {
  const { fer4782CoinsuranceRatePicker } = useFlags();

  const [isEditQuoteOpen, toggleEditQuoteOpen] = useBooleanInput(false);
  const { activeId } = useQuotesActiveId();

  const { data: quoteData } = useQuote();
  const identityId =
    getQuoteIdentityId(quoteData) || Store.get(IDENTITY_LOCAL_STORAGE_KEY);
  const quoteId = getQuoteId(quoteData);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const vetId = getQuoteVetId(quoteData);
  const { petName } = activePet;

  const { mutate: mutatePetPolicy } = useMutatePetPolicy();

  const { isReady, data: petPlanData } = usePetPlanData({
    petId: activePet.id,
    identityId,
    vetId,
  });

  const {
    activeValues,
    insurancePicker: { activePrice },
  } = petPlanData;

  const profileImage = `/assets/images/photos/plan-selection/${getProfileImage(
    activePet?.petAge,
    activePet?.petBreedSpecies
  )}`;

  const high90FeatureFlagMessage = fer4782CoinsuranceRatePicker ? ' high' : ' 90%';

  const maximumFeatureFlagMessage = fer4782CoinsuranceRatePicker ? ' ' : ' maximum';

  function getProfileImage(petAge, petBreedSpecies) {
    if (petBreedSpecies === DOG) {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'profile-image-puppy.png'
        : 'profile-image-dog.png';
    } else {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'profile-image-kitten.png'
        : 'profile-image-cat.png';
    }
  }

  function handleInsuranceChangeBundle({ annual_limit, deductible, copay }) {
    mutatePetPolicy({
      pet: { ...activePet, annual_limit, deductible, copay },
    });
  }

  useEffect(() => {
    getPetPolicy(quoteId, activePet.id)
      // mutate pet policy selection with recommended options right away
      .then(() => mutatePetPolicy({ pet: { ...activePet, ...activeValues } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      {isEditQuoteOpen ? <QuoteEditModal onClose={toggleEditQuoteOpen} /> : null}
      <div className={styles.header}>
        <h2>{petName}'s plan</h2>

        <span className={classNames(Typography.body2, styles.headerText)}>
          {headerText}
        </span>
        <LegalBody isBold className={styles.editLink} onClick={toggleEditQuoteOpen}>
          Edit pet details
        </LegalBody>
      </div>

      <div className={styles.pickerSectionGridBundles}>
        <div className={styles.pickerCompGrid}>
          <div className={styles.pickerContainer}>
            {isReady ? (
              <InsurancePickerBundle
                onAddToCart={onAddToCart}
                buttonText={buttonText}
                petPlanData={petPlanData}
                onChange={handleInsuranceChangeBundle}
                pet={activePet}
                activePrice={activePrice}
              />
            ) : null}
          </div>
        </div>

        <div className={styles.imagesGridBundle}>
          <img src={profileImage} alt='' className={styles.profileModuleImage} />
        </div>

        <div className={styles.imagesGridBlurb}>
          <div className={styles.costBlurbImage}>
            <img
              src='/assets/images/iconImages/piggy-bank.png'
              alt=''
              className={styles.piggyImg}
            />

            <LegalBody>
              Every Pumpkin plan has a
              <b>{high90FeatureFlagMessage} reimbursement rate</b> because we think
              ALL pet parents deserve{maximumFeatureFlagMessage} cash back on
              eligible veterinary bills.
            </LegalBody>
          </div>
        </div>
      </div>
    </div>
  );
}

Picker.defaultProps = {
  buttonText: 'Add to Cart',
  image: '',
  headerText: '',
};

Picker.propTypes = {
  buttonText: PropTypes.string,
  image: PropTypes.string,
  headerText: PropTypes.string,
};

export default Picker;
